import { Auth } from '@/api/auth';

export default {
	state: {
		id: '',
		authId: '',
		username: '',
		role: '',
		passwordResetId: '',
	},

	getters: {
		getId: state => state.id,
		getAuthId: state => state.authId,
		isLogin: state => state.username != null,
		getUsername: state => state.username,
		getRole: state => state.role,
		getIsNlobby: state => state.authId.indexOf('@nlobby.com') > -1,
		getPasswordResetId: state => state.passwordResetId,
	},

	mutations: {
		setId(state, payload) {
			state.id = payload;
		},
		setAuthId(state, payload) {
			state.authId = payload;
		},
		setUsername(state, payload) {
			state.username = payload;
		},
		setRole(state, payload) {
			state.role = payload;
		},
		setAuth(state, payload) {
			state.id = payload.id;
			state.authId = payload.authId;
			state.username = payload.username;
			state.role = payload.role;
		},
		setPasswordResetId(state, passwordResetId) {
			state.passwordResetId = passwordResetId;
		},
	},

	actions: {
		async LOGIN({ commit }, userData) {
			const resp = await Auth.login(userData); // api 호출
			commit('setId', resp.data.id);
			commit('setAuthId', resp.data.authId);
			commit('setUsername', resp.data.name);
			commit('setRole', resp.data.roles.code);
		},

		async LOGOUT({ commit }) {
			await Auth.logout();
			commit('setId', '');
			commit('setAuthId', '');
			commit('setUsername', '');
			commit('setRole', '');
		},

		ResetPasswordId({ commit }, userId) {
			commit('setPasswordResetId', userId);
		},

		ResetPasswordIdClear({ commit }) {
			commit('setId', '');
			commit('setAuthId', '');
			commit('setUsername', '');
			commit('setRole', '');
			commit('setPasswordResetId', '');
		},
	},
};
