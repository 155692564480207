import { Config } from '@/api/config';

export default {
	state: {
		config: {},
	},

	getters: {
		getConfig: state => state.config,
	},

	mutations: {
		setConfig(state, payload) {
			state.config = payload;
		},
	},

	actions: {
		async GET_CONFIG({ commit }) {
			const { data } = await Config.get(); // api 호출
			commit('setConfig', data);
		},
	},
};
