import Vue from 'vue';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import { createToastInterface } from 'vue-toastification';

const toastOptions = {
	// You can set your default options here
	position: 'top-center',
	timeout: 2000,
};

Vue.use(Toast, toastOptions);

// Create the interface
const pluginOptions = {
	position: 'top-center',
	timeout: 2000,
};

// Create the interface
export const toast = createToastInterface(pluginOptions);
