import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import themeConfig from './modules/themeConfig';
// import largeSidebar from './modules/largeSidebar';
// import compactSidebar from './modules/compactSidebar';
// import verticalSidebar from './modules/verticalSidebar';
import auth from './modules/auth';
import config from './modules/config';

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			storage: window.sessionStorage, // store를 session storage 에 유지
		}),
	],

	modules: {
		auth,
		config,
		themeConfig,
		// largeSidebar,
		// compactSidebar,
		// verticalSidebar,
	},
});
