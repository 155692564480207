import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import { Auth } from '@/api/auth';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: () => import('@/views/StartPage.vue'),
	},
	{
		path: '*',
		component: () => import('@/views/NotFoundPage.vue'),
	},
	{
		path: '/error',
		name: 'error',
		component: () => import('@/views/NotFoundPage.vue'),
	},
	{
		path: '/auth',
		component: () => import('@/views/auth/IndexPage.vue'),
		children: [
			{
				path: 'login',
				name: 'login',
				component: () => import('@/views/auth/LoginPage.vue'),
			},
			{
				path: 'pwInquiry',
				name: 'pwInquiry',
				component: () => import('@/views/auth/LostPassword.vue'),
			},
			{
				path: 'resetEmail',
				name: 'resetEmail',
				component: () => import('@/views/auth/ResetEmailPassword.vue'),
			},
		],
	},
	{
		path: '/app',
		redirect: '/app/meeting', // 최초페이지
		component: () => import('@/views/app/AppLayout'), // 레이아웃 호출
		children: [
			{
				path: 'meeting',
				name: 'meeting',
				component: () => import('@/views/app/meeting/IndexPage.vue'),
			},
			{
				path: 'meeting-history',
				name: 'meeting-history',
				component: () => import('@/views/app/meeting-history/IndexPage.vue'),
			},
			{
				path: 'host-users',
				name: 'host-users',
				component: () => import('@/views/app/host-users/IndexPage.vue'),
			},
			{
				path: 'user-code',
				name: 'user-code',
				component: () => import('@/views/app/user-code/IndexPage.vue'),
			},
			{
				path: 'config',
				name: 'config',
				component: () => import('@/views/app/config/IndexPage.vue'),
			},
		],
	},
	{
		path: '/view',
		component: () => import('@/views/view/IndexPage.vue'),
		children: [
			{
				path: 'reserve/:id',
				name: 'view-reserve',
				component: () => import('@/views/view/ReserveView.vue'),
			},
		]
	}
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: (to, from, savedPosition) => {
		if (to.hash) return { selector: to.hash };
		if (savedPosition) return savedPosition;

		return { x: 0, y: 0 };
	},
});

const needLogin = ['/app'];

router.beforeEach(async (to, from, next) => {
	await store.dispatch('GET_CONFIG');

	if (to.path.includes(needLogin) && store.getters.getUsername === '') {
		next('/auth/login');
	} else {
		if (to.path.includes(needLogin)) {
			await Auth.current().then(resp => {
				// console.log('data : ',resp.data)
				store.commit('setAuth', resp.data);
			});
		}
		next();
	}
});

router.afterEach(() => {});

export default router;
