import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { themePreset } from './octavia.kit/themeConfig';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);

export default new Vuetify({
	theme: themePreset.theme,
	rtl: themePreset.rtl,
	icons: {
		iconfont: 'mdi' || 'md',
	},
});
