import { axiosService } from './index';

const prefixUrl = '/api/auth/';

const Auth = {
	index() {
		return axiosService.get(prefixUrl);
	},
	current() {
		return axiosService.get(prefixUrl + 'current');
	},
	login(userData) {
		return axiosService.post(prefixUrl + 'login', userData);
	},
	logout() {
		return axiosService.post(prefixUrl + 'logout');
	},
	verificationCode(userData) {
		return axiosService.post(prefixUrl + 'verificationCode', userData);
	},
	resetPassword(form) {
		return axiosService.post(prefixUrl + 'resetPassword', form);
	},
};

export { Auth };
