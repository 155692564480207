import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/toast';
import '@/plugins/octavia.kit/base';
// import vClickOutside from 'v-click-outside'
import octaviaKit from '@/plugins/octavia.kit/octavia.kit';


// PWA-ServiceWorker
import './sw';

Vue.config.productionTip = false;

Vue.use(octaviaKit);
// Vue.use(vClickOutside);
new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App),
}).$mount('#app');
