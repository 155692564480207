<template>
	<v-app>
		<router-view :appInstall="appInstall" :appState="appState" @stateData="stateData"/>
		<!-- <customizer /> -->
	</v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	data(){
		return{
			appInstall:false,
			appState:'',
		}
	},
	metaInfo() {
		return {
			// if no subcomponents specify a metaInfo.title, this title will be used
			title: this.getConfig.customerCompany || 'n4space',
			// all titles will be injected into this template
			titleTemplate: '%s | 공간예약시스템',
		};
	},
	mounted(){
		this.checkApp();
		window.addEventListener('DOMContentLoaded', function (ev) {
			const { innerHeight } = window;
			document.documentElement.style.setProperty('--app-height',`${innerHeight}px`,);
			document.querySelector('body').style.setProperty('--app-height', `${innerHeight}px`);
		});
	},
	computed: {
		...mapGetters(['getConfig']),
	},
	methods:{
		checkApp(){
			let state = window.matchMedia('(display-mode: standalone)').matches;
			// 현재 실행된 브라우저가 앱인지 아닌지 구분
			if (state == false) {	// 앱이 아닐 경우
				this.appInstall = false;
				this.appState = "browers"
				window.addEventListener('beforeinstallprompt', (event) => {
					// 사용자가 앱을 설치하지않았을 경우에만 발생하는 리스너
					event.preventDefault()
					this.appState = "noApp"
					window.deferredPrompt = event;
				});
				window.addEventListener('appinstalled', (event) => {
					event.preventDefault();
					this.appInstall = true;
					window.deferredPrompt = null;
				});
			} else {	// 앱일경우
				this.appInstall = true;
				this.appState="accepted"
			}
		},
		// onClickAppInstall(){
		// 	let promptEvent = window.deferredPrompt;
		// 	if(promptEvent){
		// 		promptEvent.prompt();
		// 		promptEvent.userChoice.then((choiceResult) => {
		// 			if (choiceResult.outcome === "accepted") {
		// 				// this.appInstall = true;
		// 				this.appState = choiceResult.outcome;
		// 				console.log(choiceResult.outcome);
		// 			} else {
		// 				this.appState = choiceResult.outcome;
		// 				console.log(choiceResult.outcome);
		// 			}
		// 		})
		// 		promptEvent = null;
		// 		// this.appInstall = true;
		// 	} else {
		// 		return;
		// 	}
		// },
		stateData(changeApp, changeBrower){
			this.appInstall = changeApp;
			this.appState = changeBrower;
		},
	},
};
</script>
<style lang="scss" scoped>
	html, body{
		overscroll-behavior:contain;
	}
	// .app-down-area{
	// 	background:#03c75a; position:relative; z-index:333;
	// 	display:flex; justify-content: center; align-items: center;
	// 	span{
	// 		font-size:1.6rem;
	// 		color:#fff;
	// 		margin-right:10px;
	// 	}
	// 	// .app-message{
	// 	// 	font-size:1.6rem; font-weight:400;
	// 	// }
	// 	.app-down{
	// 		color:#fff;
	// 		font-weight:600;
	// 		// color:#03c75a;
	// 		&:hover, &:focus{
	// 			background:none;
	// 		}
	// 	}
	// }
</style>
