import { axiosService } from './index';

const prefixUrl = '/api/config';

const Config = {
	get() {
		return axiosService.get(prefixUrl);
	},
	save(data) {
		return axiosService.post(`${prefixUrl}-save`, data);
	},
};

export { Config };
