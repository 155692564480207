import { toast } from '@/plugins/toast';

export function setInterceptors(axiosService) {
	axiosService.interceptors.request.use(
		config => {
			// 요청을 보내기 전에 어떤 처리를 할 수 있다.
			// config.headers['Access-Control-Allow-Origin'] = '*'; // CORS 설정(모든 리소스 허용)
			// console.log('axios request :>> ', config);
			return config;
		},
		error => {
			// 요청이 잘못되었을 때 에러가 컴포넌트 단으로 오기 전에 어떤 처리를 할 수 있다.
			return Promise.reject(error);
		},
	);

	axiosService.interceptors.response.use(
		response => {
			// 서버에 요청을 보내고 나서 응답을 받기 전에 어떤 처리를 할 수 있다.
			return response;
		},
		error => {
			// 응답이 에러인 경우에 미리 전처리할 수 있다.
			const errorResponse = error.response;

			switch (errorResponse.status) {
				case 500: // Internal Server Error
					toast.warning('서버에서 요청을 처리하지 못했습니다.');
					return null;
				case 400: // Bad Request
					toast.error(errorResponse.data);
					return Promise.reject(error);
				case 401: // Unauthorized
					if (error.response.data.indexOf('중복 로그인') >= 0) {
						toast.error(
							'중복 로그인 방지를 위해 로그아웃됩니다. 로그인 화면으로 이동합니다.',
						);
					} else {
						toast.error(
							'인증 세션이 만료되었습니다. 로그인 화면으로 이동합니다.',
						);
					}
					window.setTimeout(() => {
						location.replace('/#/auth/login');
					}, 2000);
					return null;
				case 403: // Forbidden
					return null;
				case 404: // Not Found
					return Promise.reject(error);
				case 405: // Method Not Allowed
					toast.warning('참조되고 있는 코드는 삭제할 수 없습니다.');
					return null;
				default: // 에러 페이지 노출 방지
					toast.warning('오류가 발생하였습니다.');
					return null;
			}
		},
	);

	return axiosService;
}
