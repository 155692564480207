import axios from 'axios';
import { setInterceptors } from './common/interceptors';

function createAxiosService() {
	return setInterceptors(axios.create({
		headers: {
			"Cache-Control": "no-cache",
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
		}
	}));
}

export const axiosService = createAxiosService();
