// theme Main scss
import '@/assets/scss/octavia-design-system/octavia-design-system.scss';

// javascript import for when you're importing the css directly in your javascript
import 'vue-navigation-bar/dist/vue-navigation-bar.css';

// import the library
import VueMeta from 'vue-meta';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import VueNavigationBar from 'vue-navigation-bar';

// end plugins

export default {
	install(Vue) {
		Vue.use(VueMeta);
		Vue.component('vue-navigation-bar', VueNavigationBar);
		Vue.component('VuePerfectScrollbar', VuePerfectScrollbar);

		// 레이아웃
		// Vue.component('CustomSidebar', () =>
		// 	import('@/layouts/common-layout/Index'),
		// );
		// Vue.component('VerticalWhiteSidebar', () =>
		// 	import('@/layouts/vertical-white/Index'),
		// );

		// Vue.component('HorizontalBar', () => import('@/layouts/horizontal/Index'));
		// Vue.component('VerticalSidebar', () => import('@/layouts/vertical/Index'));

		// Vue.component('VerticalCompactSidebar', () =>
		// 	import('@/layouts/vertical-compact-layout/Index'),
		// );
		// Vue.component('VerticalSaasLayout', () =>
		// 	import('@/layouts/vertical-saas-layout/Index'),
		// );
		// Vue.component('VerticalSix', () => import('@/layouts/vertical-six/Index'));
	},
};
