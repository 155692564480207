import colors from 'vuetify/lib/util/colors';

export const themeConfig = {
	disableCustomizer: false, // options[Boolean] : true, false(default)

	rtl: false, // options[Boolean] : true, false(default)
	verticalSidebarMini: false, // options[Boolean] : true, false(default)
	verticalSidebarDrawer: false, // options[Boolean] : true, false(default)
	verticalCompactSidebarDrawer: false, // options[Boolean] : true, false(default)
	verticalSaasSidebarDrawer: false, // options[Boolean] : true, false(default)
	showBreadcrumb: false, // options[Boolean] : true, false(default)

	layout: 'n4space', // VerticalWhiteSidebar / HorizontalBar / VerticalSidebar / VerticalCompactSidebar / VerticalSaasLayout / VerticalSix / CustomSidebar, n4space

	isLoading: false,

	isDark: false,
	verticalSidebarDrawerColor: 'white', // primary,white,sucsess,warning,etc
	appBarColor: 'white', // primary,white,sucsess,warning,etc
};

const mq = window.matchMedia('(prefers-color-scheme: light)');

export const themePreset = {
	breakpoint: {
		scrollBarWidth: 16,
		thresholds: {
			xs: 600,
			sm: 960,
			md: 1280,
			lg: 1920,
		},
	},

	rtl: false,

	theme: {
		// dark: mq.matches,

		default: 'light',
		disable: false,
		options: {
			cspNonce: undefined,
			customProperties: true,
			minifyTheme: undefined,
			themeCache: undefined,
		},
		themes: {
			light: {
				// primary: colors.blue, 라이트모드 컬러 색상
				primary: '#19ce60',
				secondary: '#304156',
				success: colors.green,
				danger: colors.red,
				warning: colors.deepOrange,
				info: colors.indigo,

				// dark: '#242939',

				background: '#f2f3f8',
			},
			// dark: {
			// 	// primary: colors.blue, 다크모드 컬러 색상
			// 	primary: '#849c23',
			// 	secondary: '#304156',
			// 	success: colors.green,
			// 	danger: colors.red,
			// 	warning: colors.deepOrange,
			// 	info: colors.indigo,
			// },
		},
	},
};
